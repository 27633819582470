import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css'; 
import { useMainContext } from '../../context/MainContext';

const NotFound = ({path}) => {
    const [state] = useMainContext()
  const navigate = useNavigate();
  const {textsPageNotFound} =  require(`../../constants/${path}.js`)

  const goHome = () => {
    if (state.languageSite === 'pt' || state.languageSite === 'br') {
      
        navigate('/')
        
      } else {
        
        navigate('/' + state.languageSite + '/')

      }
  };

  return (
    <div className="notfound-container">
      <div className="notfound-content">
        <h1 className="notfound-title">404</h1>
        <p className="notfound-message">{textsPageNotFound.mainText}</p>
        <button className="notfound-button" onClick={goHome}>{textsPageNotFound.subText}</button>
      </div>
    </div>
  );
};

export default NotFound;
