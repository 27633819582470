const createLanguageCombination = (languages) => {
    const languageCombinations = [];
  
    for (let i = 0; i < languages.length; i++) {
        const languagePerType = []
        for (let j = 0; j < languages.length; j++) {
            if (i !== j) {
                languagePerType.push({
                origin: languages[i],
                translated: languages[j],
                value: 0,
                });
            }
        }
        languageCombinations.push(languagePerType)
        
    }
  
    return languageCombinations;
}
  
const languages = [
    "Português",
    "Inglês",
    "Espanhol",
    "Francês",
    "Alemão",
    "Italiano",
    "Holandês",
    "Russo",
    "Japonês",
    "Chinês",
    "Árabe",
    "Hindi",
    "Coreano",
    "Turco",
    "Sueco",
    "Polonês",
    "Vietnamita",
    "Tailandês",
    "Grego",
    "Dinamarquês"
  ]

export const initialState = {
    user: null,
    filePending: [],
    selectValues: {
        origin: '',
        translation: []
    },
    deadlines: {},
    cart: undefined,
    languages,
    showValues: false,
    languageCombinations: createLanguageCombination(languages),
    languageCombinationsUS: null,
    multiplers: {economy: 0.37, expert: 1, premium: 1.37},
    defaultValue: 0.11,
    archiveTypes: [],
    archiveTypeSelected: {},
    languagesData: [
        { language: 'Português', types: ['Brasil', 'Portugal'] },
        { language: 'Inglês', types: ['Estados Unidos', 'Reino Unido'] },
        { language: 'Chinês', types: ['Simplificado', 'Taiwanês', 'Hong Kong'] },
        { language: 'Espanhol', types: ['Espanha', 'América Latina'] },
        { language: 'Alemão', types: ['Alemanha', 'Austrália'] },
        { language: 'Francês', types: [] },
        { language: 'Italiano', types: [] },
        { language: 'Holandês', types: [] },
        { language: 'Russo', types: [] },
        { language: 'Japonês', types: [] },
        { language: 'Árabe', types: [] },
        { language: 'Hindi', types: [] },
        { language: 'Coreano', types: [] },
        { language: 'Turco', types: [] },
        { language: 'Sueco', types: [] },
        { language: 'Polonês', types: [] },
        { language: 'Vietnamita', types: [] },
        { language: 'Tailandês', types: [] },
        { language: 'Grego', types: [] },
        { language: 'Dinamarquês', types: [] }
      ],
    languagesDataUS: [
        { language: 'Portuguese', types: ['Brazil', 'Portugal'] },
        { language: 'English', types: ['United States', 'United Kingdom'] },
        { language: 'Chinese', types: ['Simplified', 'Taiwanese', 'Hong Kong'] },
        { language: 'Spanish', types: ['Spain', 'Latin America'] },
        { language: 'German', types: ['Germany', 'Australia'] },
        { language: 'French', types: [] },
        { language: 'Italian', types: [] },
        { language: 'Dutch', types: [] },
        { language: 'Russian', types: [] },
        { language: 'Japanese', types: [] },
        { language: 'Arabic', types: [] },
        { language: 'Hindi', types: [] },
        { language: 'Korean', types: [] },
        { language: 'Turkish', types: [] },
        { language: 'Swedish', types: [] },
        { language: 'Polish', types: [] },
        { language: 'Vietnamese', types: [] },
        { language: 'Thai', types: [] },
        { language: 'Greek', types: [] },
        { language: 'Danish', types: [] }
    ],
    fileUpload: [],
    valueAutomatic: 0.45,
    languageSite: 'br'
};
