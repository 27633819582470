import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useMainContext } from './context/MainContext/index.jsx';
import { onAuthStateChanged } from 'firebase/auth';
import NotFound from './template/NotFound/index.js';
import { collection, onSnapshot } from 'firebase/firestore';
import { loadConfig } from './utils/getInfos.js';
import { db } from './firebase/config.js';
const Home = lazy(() => import('./template/Home/index.jsx'))
const Navbar = lazy(() => import('./components/Layout/Navbar/index.jsx'))
const Terms = lazy(() => import('./template/Terms/index.jsx'));
const Login = lazy(() => import('./template/Login/index.jsx'));
const Register = lazy(() => import('./template/Register/index.jsx'));
const ResetPassword = lazy(() => import('./template/ResetPassword/index.jsx'));
const Order = lazy(() => import('./template/Order/index.jsx'));
const Checkout = lazy(() => import('./template/Checkout/index.jsx'));
const Config = lazy(() => import('./template/Config/index.jsx'));
const Admin = lazy(() => import('./template/Admin/index.jsx'));

function isMobileDevice() {
  const userAgent = navigator.userAgent;
  const mobileKeywords = ['Mobile', 'Android', 'iOS', 'iPhone', 'iPad', 'Windows Phone'];
  
  return mobileKeywords.some(keyword => userAgent.includes(keyword));
}

const LanguageWrapper = ({ isAdmin, hiddenNavbar, setName, name, user, state, language, setHiddenNavbar }) => {
  return (
    <Routes>
      <Route path="/" element={<Navbar isAdmin={isAdmin} hiddenNavbar={hiddenNavbar} setName={setName} name={name} />}>
        {isAdmin ? (
          <>
            <Route index element={<Suspense fallback={' '}><Admin user={user} /></Suspense>} />
            <Route path="config" element={<Suspense fallback={' '}><Config user={user} /></Suspense>} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route index element={<Suspense fallback={' '}><Home path={language === 'pt' ? 'index' : 'index_us'} /></Suspense>} />
            <Route path="order" element={user ? <Suspense><Order name={name} path={language === 'pt' ? 'index' : 'index_us'} setHiddenNavbar={setHiddenNavbar} /></Suspense> : <Navigate to="/login" />} />
            <Route path="checkout/:id" element={user && state.cart !== undefined ? <Suspense><Checkout path={language === 'pt' ? 'index' : 'index_us'}  name={name} /></Suspense> : <Navigate to="/register" />} />
            <Route path="checkout" element={user && state.cart !== undefined ? <Suspense><Checkout name={name} path={language === 'pt' ? 'index' : 'index_us'}  /></Suspense> : <Navigate to="/register" />} />
            <Route path="terms" element={<Suspense><Terms path={language === 'pt' ? 'index' : 'index_us'}  /></Suspense>} />
            <Route path="login" element={!user ? <Suspense><Login path={language === 'pt' ? 'index' : 'index_us'}  /></Suspense> : state.cart === undefined ? <Navigate to="/" /> : <Navigate to={state.languageSite === 'br' || state.languageSite === 'pt' ? '/checkout' : '/' + state.languageSite + '/checkout'} />} />
            <Route path="register" element={!user ? <Suspense><Register path={language === 'pt' ? 'index' : 'index_us'}  setName={setName} /></Suspense> : state.cart === undefined ? <Navigate to="/" /> : <Navigate to={state.languageSite === 'br' || state.languageSite === 'pt' ? '/checkout' : '/' + state.languageSite + '/checkout'} />} />
            <Route path="resetpassword" element={!user ? <Suspense><ResetPassword path={language === 'pt' ? 'index' : 'index_us'}  /></Suspense> : state.cart === undefined ? <Navigate to="/" /> : <Navigate to="/checkout" />} />
          </>
        )}

        {/* <Route path="empresas" element={<Suspense fallback={' '}><Company /></Suspense>} /> */}
        <Route path='/*' element={<NotFound path={language === 'pt' ? 'index' : 'index_us'} />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  const [state, actions, dispatch] = useMainContext()
  const userLanguage = navigator.language || navigator.userLanguage;
  const [user, setUser] = useState(undefined)
  const [name, setName] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [hiddenNavbar, setHiddenNavbar] = useState(false)

  useEffect(() => {
      const collectionRef = collection(db, 'configSenting')
    onSnapshot(collectionRef, (snapshot) => {

      loadConfig(dispatch)
    })
},[])


  useEffect(() =>{
    if (state.auth) {
      const unsubscribe = onAuthStateChanged(state.auth, async (user) => {
        setUser(user);
        if (user !== null) {
          const isAdmin =
            user.email === process.env.REACT_APP_ADMIN_EMAIL_P ||
            user.email === process.env.REACT_APP_ADMIN_EMAIL_S ||
            user.email === process.env.REACT_APP_ADMIN_EMAIL_T 
          setIsAdmin(isAdmin);
          actions.setAccountInfo({
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
          });
        } else {
          setIsAdmin(false);
          actions.setAccountInfo(undefined)
        }
      });
      return () => unsubscribe();
    }
  }, [state.auth, actions])

  useEffect(() => {
    let language

    if (userLanguage.split('-').length === 2) {
      language = userLanguage.split('-')[1].toLowerCase()
    } else {
      language = userLanguage.split('-')[0].toLowerCase()
    }
    if (!window.location.pathname.includes("/" + language) && (language === 'us' || language === 'en')) {
      window.location.href = "/" + language
    }
    if (window.location.pathname.includes("/br")) {
      window.location.href = "/"
    }
    console.log(language);
    actions.changeLanguageSite(language);
  },[])

  if (!state.languageSite) {
    return <div></div>
  }
  if (state.user === null) {
    return
  }

  return (
    <div>
          <BrowserRouter >
          
          <Routes>
          <Route path={`/us/*`} element={<LanguageWrapper setHiddenNavbar={setHiddenNavbar} isAdmin={isAdmin} hiddenNavbar={hiddenNavbar} setName={setName} name={name} user={user} state={state} language="en" />} />
          <Route path={`/en/*`} element={<LanguageWrapper setHiddenNavbar={setHiddenNavbar} isAdmin={isAdmin} hiddenNavbar={hiddenNavbar} setName={setName} name={name} user={user} state={state} language="en" />} />
          <Route path="/*" element={<LanguageWrapper setHiddenNavbar={setHiddenNavbar} isAdmin={isAdmin} hiddenNavbar={hiddenNavbar} setName={setName} name={name} user={user} state={state} language="pt" />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
};

export default App;