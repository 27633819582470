import { getAuth } from 'firebase/auth';
import * as actionTypes from '../context/MainContext/actions-types';
import axios from 'axios';


 function parseFirestoreData(data) {
  const parsedData = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (value.mapValue) {
        parsedData[key] = parseFirestoreData(value.mapValue.fields);
      } else if (value.arrayValue) {
        parsedData[key] = value.arrayValue.values.map(item => {
          if (item.mapValue) {
            return parseFirestoreData(item.mapValue.fields);
          } else {
            return item.integerValue !== undefined ? item.integerValue : 
                   item.stringValue !== undefined ? item.stringValue : 
                   item.booleanValue !== undefined ? item.booleanValue :
                   item.doubleValue !== undefined ? item.doubleValue :
                   null;
          }
        });
      } else {
        parsedData[key] = value.integerValue !== undefined ? value.integerValue : 
                         value.stringValue !== undefined ? value.stringValue : 
                         value.booleanValue !== undefined ? value.booleanValue :
                         value.doubleValue !== undefined ? value.doubleValue :
                         null;
      }
    }
  }

  return parsedData;
}

export const loadConfig = async (dispatch) => {

  const auth = getAuth()

  const dataFirebase = await axios.get(`https://firestore.googleapis.com/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/configSenting/` + process.env.REACT_APP_ID_CONFIG)

  const data = parseFirestoreData(dataFirebase.data.fields)

  return dispatch({
    type: actionTypes.CHANGECONFIGVALUES, 
    deadlines: data.deadline, 
    multipler: data.multiplers, 
    languageCombinations: data.languageValues, 
    defaultValue: data.values,
    archiveTypes: data.archiveTypes,
    valueAutomatic: data.valueAutomatic,
    valueAutomaticUS: data.valueAutomaticUS,
    archiveTypesUS: data.archiveTypesUS,
    languageCombinationsUS: data.languageValuesUS,
    defaultValueUS: data.defaultValueUS,
    auth
  })

}
